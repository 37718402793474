<script>
import {mapGetters} from "vuex";

export default {
  name: "CommunityCard",
  props: {
    card: {
      type: Object,
      required: false,
    },
    cardStatus: {
      type: String,
      required: true,
      default: '',
    }
  },
  data() {
    return {
      flipTimer: null,
      flipped: false,
      showFront: false,
    }
  },
  computed: {
    ...mapGetters('config', ['cardResourceNumber']),
    cardBack() {
      return require(`@/assets/v2/img/card_back_1.webp`);
    },
    cardAsset() {
      if (!this.showFront) {
        return this.cardBack;
      }

      let {suit, nick} = this.card;
      const rankAbbr = nick.charAt(0) || '';
      let rank = rankAbbr.toLowerCase() === 't' ? 10 : rankAbbr.toUpperCase();

      return require(`@/assets/v2/svg/deck${this.cardResourceNumber}/${suit.toLowerCase()}_${rank}.svg`);
    },
  },
  mounted() {
    setTimeout(() => {
      this.flipped = true;
    }, 200)
  },
  watch: {
    flipped: {
      immediate: true,
      handler(value) {
        value && setTimeout(() => {
          this.showFront = true;
        }, 200);
      }
    }
  }
}
</script>

<!-- active 효과 핸드랭킹 판단 로직 작업 후 적용 -->
<template>
  <div
    ref="card"
    class="game_card"
    :class="{
      active: cardStatus === 'active',
      disabled: cardStatus === 'disabled',
      card_back: !showFront,
      card_front: showFront,
      'is-flipped': flipped
    }"
  >
    <slot :is-flipped="flipped" :show-front="showFront" :asset="cardAsset"></slot>
  </div>
</template>

<style>
.card_back.is-flipped {
  animation: flip .2s ease-in-out forwards;
}

.card_front.is-flipped {
  animation: flip .0s ease-in-out reverse;
  animation-delay: .2s;
}

@keyframes flip {
  to {
    transform: rotateY(180deg);
  }
}

</style>